import { PageProps } from 'gatsby';
import { CollectionData } from 'packages/innedit';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import params from '~/params/collection.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const PageAdminCollectionCreate: FC<PageProps & UserProps> = ({
  params: { espaceId },
}) => {
  const model = new CollectionData({
    espaceId,
    params,
  });

  return (
    <TemplateAdmin>
      <div className="md:mx-6">
        <Form
          displayClose={false}
          docId={model.getNewDocId()}
          model={model}
          type="create"
        />
      </div>
    </TemplateAdmin>
  );
};

export default requireUser(PageAdminCollectionCreate, 'admin');
